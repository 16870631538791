import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Talk from "../../components/talk";

import '../../components/index.scss';
import HomeLink from "../../components/home-link";

const XCUITestingPage = () => {
  const imageQuery = useStaticQuery(graphql`
    query {
      cover: file(relativePath: { eq: "talks/xcui-testing2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const title = "XCUI Testing: lo bueno, lo malo y lo feo";
  const summary = `I gave this talk in our local CocoaHeads chapter, it was a gentle introduction into XCUITesting.
      The new, at the time, UI testing framework developed by Apple.`;

  return <Layout>
    <SEO title={title} description={summary}/>
    <HomeLink/>
    <Talk
      imageQuery={imageQuery}
      name={title}
      slidesURL={"https://docs.google.com/presentation/d/1_nqNTcaft8RAqPU7OR4VMZKxZs2J0WRx0qgeL2WPNDg/edit?usp=sharing"}
      year={2016}
      summary={summary}
    />
  </Layout>
};

export default XCUITestingPage
